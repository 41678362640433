















































































.previewContainer {
  background-color: #eee;
  border: 2px dashed #ccc;
  padding: 16px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
}
